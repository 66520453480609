<template>
  <v-container class="webhook-management">
    <data-preparation-overlay v-if="availableWebhookDefinitions === null"></data-preparation-overlay>
    <div v-else>
      <v-row>
        <v-col cols="12">
          <div @click="redirectTo('SingleStorePage', { id: currentShop.id })">
            <v-icon>keyboard_arrow_left</v-icon>Back
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h2">
            {{ currentShop.store_domain }}'s webhooks setting
          </h1>
          <v-tabs color="blue darken-1" right>
            <v-tab>Known Webhook Toggle</v-tab>
            <v-tab>Custom Webhooks</v-tab>
            <v-tab>Raw Webhooks Data</v-tab>
            <v-tab>Debug (Beeceptor) Webhook Toggle</v-tab>

            <v-tab-item>
              <v-card>
                <v-card-title class="text-h5">Toggle Normal System Webhooks</v-card-title>
                <v-card-subtitle class="text-h6 mt-1">
                  <v-icon>notifications_active</v-icon>Predefined Webhooks
                </v-card-subtitle>
                <v-divider class="mb-3"></v-divider>
                <v-list v-if="availableWebhookDefinitions != null">
                  <v-list-item
                    class="webhook-setting-item"
                    v-for="webhookDefinition in availableWebhookDefinitions"
                    :key="webhookDefinition.address"
                  >
                    <v-row>
                      <v-col cols="4">
                        <v-card-text class="mr-5 pt-0 pb-0">
                          <v-icon class="float-left icon"
                            >notifications_none</v-icon
                          >
                          <div class="webhook-name">
                            {{ webhookDefinition.name }}
                          </div>
                          <div class="webhook-address">
                            {{ webhookDefinition.address }}
                          </div>
                        </v-card-text>
                      </v-col>
                      <v-col cols="7">
                        <v-card-text
                          v-html="webhookDefinition.description"
                        ></v-card-text>
                      </v-col>
                      <v-col cols="1">
                        <v-switch
                          class="mt-0"
                          v-model="webhookDefinition.enabled"
                          inset
                          dense
                          color="blue darken-1"
                          @change="changeConfig(webhookDefinition)"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-card-title class="text-h5 d-flex justify-space-between pt-4">
                  <span>
                    Add/ Remove individual webhook
                  </span>
                  <v-dialog v-model="customWebhookDialog" width="500">

                    <template v-slot:activator="{ on, attrs }">
                      <v-btn elevation="0" color="primary" tile v-bind="attrs" v-on="on">Add Custom Webhook</v-btn>
                    </template>

                    <v-card class="pa-5 relative">
                      <h2 class="mb-2">Add Custom Webhook</h2>
                      <v-divider class="mb-6"></v-divider>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-select :rules="[v => !!v || 'Item is required']" dense v-model="customWebhook.topic" :items="currentShop.available_webhook_topics" label="Topic" required outlined></v-select>
                        <v-text-field dense label="URL (https)" outlined required v-model="customWebhook.url" :rules="rules"></v-text-field>
                        <v-btn color="primary" @click="createCustomWebhook" :loading="isLoading" :disabled="disableAddHookButton" tile elevation="0">Add</v-btn>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-card-title>
                <v-card-subtitle class="text-h6 mt-1">
                  <v-icon>notifications_active</v-icon>Existing Webhooks
                </v-card-subtitle>
                <v-divider class="mb-3"></v-divider>
                <v-card-text>
                  <v-card
                    tile
                    light
                    v-for="(webhook, i) in rawWebhooksData"
                    :key="i"
                    class="pl-2 mb-2"
                  >
                    <v-row>
                      <v-col>
                        <v-alert dense text type="info">
                          {{ webhook.topic }}
                          <small>@ {{ webhook.created_at | humanReadableDate }}</small>
                        </v-alert>
                        <div>{{ webhook.address }}</div>
                      </v-col>
                      <v-col cols="1" class="text-right">
                        <v-icon class="mr-2" @click="removeWebhook(webhook)"
                          >close</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card class="p-3">
                <v-icon
                  @click="openWebhookRawDataDialog = false"
                  class="float-right"
                  >close</v-icon
                >
                <v-card-title class="text-h5">Plaform RAW Data</v-card-title>
                <v-card-subtitle class="text-h6 mt-1">
                  <v-icon class="mr-3">mdi-code-json</v-icon>
                  [{{ currentShop.platform }}]
                </v-card-subtitle>
                <v-card light tile class="dialog-raw-data">
                  <v-card-text>
                    <pre>{{ rawWebhooksData }}</pre>
                  </v-card-text>
                </v-card>
                <v-card-actions class="p-5">
                  <small>** Syncio transformed platform raw data</small>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-card-title class="text-h5">Toggle Debug System Webhooks</v-card-title>
                <v-card-subtitle class="text-h6 mt-1">
                  <v-icon>notifications_active</v-icon> Predefined Webhooks
                </v-card-subtitle>
                <v-divider class="mb-3"></v-divider>
                <v-list v-if="availableDebugWebhookDefinitions !== null">
                  <v-list-item
                      class="webhook-setting-item"
                      v-for="webhookDefinition in availableDebugWebhookDefinitions"
                      :key="webhookDefinition.address"
                  >
                    <v-row>
                      <v-col cols="4">
                        <v-card-text class="mr-5 pt-0 pb-0">
                          <v-icon class="float-left icon"
                          >notifications_none</v-icon
                          >
                          <div class="webhook-name">
                            {{ webhookDefinition.name }}
                          </div>
                          <div class="webhook-address">
                            {{ webhookDefinition.address }}
                          </div>
                        </v-card-text>
                      </v-col>
                      <v-col cols="7">
                        <v-card-text
                            v-html="webhookDefinition.description"
                        ></v-card-text>
                      </v-col>
                      <v-col cols="1">
                        <v-switch
                            class="mt-0"
                            v-model="webhookDefinition.enabled"
                            inset
                            dense
                            color="blue darken-1"
                            @change="changeConfig(webhookDefinition,true)"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-col>

        <v-overlay v-if="openWebhookUpdateConfirmationDialog">
          <v-card light class="pa-5">
            <v-icon @click="closeDialog()" class="float-right">close</v-icon>
            <v-card-title>
              Confirm
              <span v-if="currentWebhook.enabled">Turn On</span>
              <span v-else>Turn Off</span>
              {{ currentWebhook.name }} Webhook
            </v-card-title>
            <v-card-subtitle>URI: {{ currentWebhook.address }}</v-card-subtitle>
            <v-card-text v-html="currentWebhook.description"></v-card-text>
            <v-card-text>
              Are you sure you want to
              <span v-if="currentWebhook.enabled">turn on</span>
              <span v-else>turn off</span> the webhook?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <div class="buttons">
                <v-btn @click="closeDialog()">No</v-btn>
                <v-btn
                  color="primary"
                  class="ml-3"
                  @click="submitCurrentWebhookSetting()"
                  >Yes</v-btn
                >
              </div>
            </v-card-actions>
          </v-card>
        </v-overlay>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import {
  FETCH_AVAILABLE_WEBHOOK_DEFINITIONS,
  FETCH_CURRENT_SHOP,
  UPDATE_WEBHOOK_SETTING,
  FETCH_DEBUG_WEBHOOK_DEFINITIONS
} from "@/store/actions.type";
import DataPreparationOverlay from "@/views/components/DataPreparationOverlay";
import { ShopApiService } from "@/common/api.service";

export default {
  name: "StoreWebhooksManagementPage",
  data: function() {
    return {
      item: 1,
      currentShopId: null,
      openWebhookUpdateConfirmationDialog: false,
      currentWebhook: null,
      openWebhookRawDataDialog: false,
      customWebhookDialog: false,
      customWebhook: {
        topic: "",
        url: "",
      },
      valid: true,
      rules: [
        (value) => !!value || "Required.",
        (value) => this.isURL(value) || "URL is not valid",
      ],
      isLoading: false
    };
  },
  components: {
    DataPreparationOverlay,
  },
  computed: {
    ...mapState("shops", [
      "currentShop",
      "availableWebhookDefinitions",
      "rawWebhooksData",
      "availableDebugWebhookDefinitions",
    ]),

    disableAddHookButton() {
      const { topic, url } = this.customWebhook;
      return !topic || !url || !this.valid;
    }
  },
  created() {
    this.currentShopId = this.$route.params.id;
    this.$store.dispatch(`shops/${FETCH_AVAILABLE_WEBHOOK_DEFINITIONS}`, {
      shopId: this.currentShopId,
    });
    this.$store.dispatch(`shops/${FETCH_DEBUG_WEBHOOK_DEFINITIONS}`, {
      shopId: this.currentShopId,
    });
    if (this.currentShop === null) {
      this.$store.dispatch(`shops/${FETCH_CURRENT_SHOP}`, this.currentShopId);
    }
  },
  methods: {
    isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === "https:";
    },

    async createCustomWebhook() {
      this.isLoading = true;
      try {
        const res = await this.$store.dispatch('webhooks/createCustomWebhook', {
          payload: {
            ...this.customWebhook,
          },
          store_id: this.$route.params.id
        });

        this.customWebhook = {
          topic: "",
          url: ""
        }
        this.customWebhookDialog = false;
        await this.$store.dispatch(`shops/${FETCH_AVAILABLE_WEBHOOK_DEFINITIONS}`, {
          shopId: this.currentShopId,
        });
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      } catch (error) {
        this.customWebhook = {
          topic: "",
          url: ""
        }
        this.customWebhookDialog = false;
        this.$store.dispatch(`shops/${FETCH_AVAILABLE_WEBHOOK_DEFINITIONS}`, {
          shopId: this.currentShopId,
        });
      } finally {
        this.isLoading = false;
        this.$refs.form.reset();
      }
      
    },
    changeConfig(webhook, debug = false) {
      this.openWebhookUpdateConfirmationDialog = true;
      this.currentWebhook = webhook;
      this.debug = debug
    },
    submitCurrentWebhookSetting() {
      if (this.currentWebhook != null) {
        this.$store
          .dispatch(`shops/${UPDATE_WEBHOOK_SETTING}`, {
            shopId: this.currentShopId,
            webhookSetting: this.currentWebhook,
            debug: this.debug
          })
          .finally(() => {
            // this.currentWebhook = null;
            this.openWebhookUpdateConfirmationDialog = false;
          })
          .finally(() => {
            this.$store.dispatch(
              `shops/${FETCH_AVAILABLE_WEBHOOK_DEFINITIONS}`,
              {
                shopId: this.currentShopId,
              }
            );
            this.$store.dispatch(
                `shops/${FETCH_DEBUG_WEBHOOK_DEFINITIONS}`,
                {
                  shopId: this.currentShopId,
                }
            );
            this.closeDialog();
          });
      }
    },
    closeDialog() {
      this.currentWebhook.enabled = !this.currentWebhook.enabled;
      // this.currentWebhook = null;
      this.openWebhookUpdateConfirmationDialog = false;
    },
    redirectTo(routeName, param) {
      this.$router.push({ name: routeName, param: param });
    },
    removeWebhook(webhook) {
      let value = confirm("Are you sure you want to remove this webhook?");
      if (value) {
        ShopApiService.removingShopWebhook(this.currentShopId, webhook).then(
          ({ data }) => {
            if (data.success) {
              this.$store.dispatch(
                `shops/${FETCH_AVAILABLE_WEBHOOK_DEFINITIONS}`,
                {
                  shopId: this.currentShopId,
                }
              );
            }
          }
        );
      }
    },
  },
};
</script>
<style lang="scss">
.webhook-management {
  .buttons {
    float: right;
  }
  .webhook-setting-item {
    border-bottom: 1px solid #ccc;
    &:last-child {
      border-bottom: 0px;
    }
    .icon {
      margin-left: -25px;
      margin-top: 5px;
    }
    .webhook-name {
      background-color: #666;
      color: #fff;
      padding: 5px 15px;
      border-radius: 3px;
      margin-bottom: 2px;
    }
    .webhook-address {
      color: #777;
      padding: 5px 15px;
      border-radius: 5px;
    }
  }
  .dialog-raw-data {
    overflow-y: scroll;
  }
}
</style>
